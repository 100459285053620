.footer {
    background-color: black;
    color: white;
    padding: 0 0 1rem 0;
    display: flex;
  }
  
  h1 {
    margin: 3rem 0;
  }
  
  .div1 {
    width: 40%;
    margin: 2rem 1rem;
  }
  
  .h23 {
    padding-top: 1rem;
  }
  
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .contact input {
    padding: 8px;
    border-radius: 7px;
    margin: 0.5rem 0.5rem;
  }
  
  .namer {
    width: 70%;
    margin: 0.2rem 0 0.2rem 0.2rem;
  }
  
  .mailer {
    width: 70%;
    margin: 0.2rem 0 0.2rem 0.2rem;
  }
  
  .msge {
    width: 95%;
    margin: 0.2rem 0 0.2rem 0.2rem;
  }
  
  .btnsend {
    margin: 0.8rem;
  }
  
  .div2 {
    width: 20%;
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
    align-items: center;
  }
  
  .div2 a {
    text-decoration: none;
    color: white;
    padding: 4px;
  }
  
  .div2 a:hover {
    color: yellow;
    transform: scale(1.1);
  }
  
  .div3 {
    width: 25%;
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
  }
  
  .div3 a {
    text-decoration: none;
    color: white;
    padding: 4px;
  }
  
  .div3 a:hover {
    color: yellow;
    transform: scale(1.1);
  }
  
  .div4 {
    width: 25%;
    margin: 2rem 1rem;
  }
  
  .div4 a {
    text-decoration: none;
    color: white;
    padding: 8px;
  }
  
  @media screen and (max-width: 850px) {
    .footer {
      background-color: black;
      color: white;
      padding: 0 0 1rem 0;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  
    h1 {
      margin: 2rem 0;
    }
  
    .div1 {
      width: 80%;
      align-items: center;
    }
  
    .contact {
      align-items: center;
    }
  
    .contact input {
      padding: 8px;
      border-radius: 7px;
      margin: 0.5rem 0.5rem;
    }
  
    .namer {
      width: 70%;
      margin: 0.2rem 0 0.2rem 0.2rem;
    }
  
    .mailer {
      width: 70%;
      margin: 0.2rem 0 0.2rem 0.2rem;
    }
  
    .msge {
      width: 95%;
      margin: 0.2rem 0 0.2rem 0.2rem;
    }
  
    .btnsend {
      margin: 1rem;
    }
  
    .div2 {
      display: flex;
      flex-direction: column;
      margin: 2rem 1rem;
      width: 100%;
      align-items: center;
    }
  
    .div2 a {
      text-decoration: none;
      color: white;
      padding: 4px;
      font-size: 1rem;
    }
  
    .div3 {
      display: flex;
      flex-direction: column;
      margin: 2rem 1rem;
      width: 100%;
      align-items: center;
    }
  
    .div3 a {
      text-decoration: none;
      color: white;
      padding: 4px;
      font-size: 1rem;
    }
  
    .div4 {
      margin: 2rem 1rem;
      width: 100%;
      align-items: center;
    }
  
    .div4 a {
      text-decoration: none;
      color: white;
      padding: 8px;
      margin: 2px;
    }
  }
  