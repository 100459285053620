*{
  margin:0;
  padding:0;
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  
/*
  
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&family=Poppins:ital,wght@0,100;0,400;0,500;0,600;1,100&display=swap");


*/
/* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-family: "Poppins", sans-serif;


    //font-family: 'Playfair Display', sans-serif;
}
*/

/*navbar css*/
/*
.navbar {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    line-height: 26px;
    text-transform: uppercase;
    box-shadow: 0 8px 6px -6px gray;
    border-bottom: 0.3px;

}

.total {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.register {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    background-color: antiquewhite;
    width: 40%;
}

.register input {
    margin-top: 25px;
    margin-bottom: 25px;
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-family: "Poppins", sans-serif;
}

.App {
    text-align: center;
}

a {
    text-decoration: none;
}
*/